<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="mb-6">
            <span class="title is-size-5 mb-2">To Be Invoiced
            </span>
            <button class="button is-small is-info is-pulled-right"
              @click="openSummaryModal">
              <span class="icon">
                <i class="mdi mdi-eye" />
              </span>
              <span>
                View Summary
              </span>
            </button>
            <div>
              <p v-if="toBeInvoiceAssessments.length > 0"
                class="is-size-7">Select jobs to raise invoice/s</p>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>{{ columnsToBeInvoiced[0] }}</th>
                    <th>{{ columnsToBeInvoiced[1] }}</th>
                    <th class="has-text-right">{{ columnsToBeInvoiced[2] }} <p class="is-size-8">Ex GST</p>
                    </th>
                    <th class="has-text-right">{{ columnsToBeInvoiced[3] }} <p class="is-size-8">Ex GST</p>
                    </th>
                    <th class="has-text-right">{{ columnsToBeInvoiced[4] }} <p class="is-size-8">Ex GST</p>
                    </th>
                    <th>{{ columnsToBeInvoiced[5] }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="assessment in toBeInvoiceAssessments"
                    :key="assessment.subQuoteNo">
                    <td class="has-vertical-middle">{{ assessment.subQuoteNo === 0 ? 'Main' : `Supp-${assessment.subQuoteNo}` }}</td>
                    <td class="has-vertical-middle">{{ $filters.formatDateTimezone(assessment.authorisedAssessment.createdDate, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle has-text-right money-input">{{ $filters.formatCurrency(assessment.quotedAmount, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle has-text-right money-input">{{ $filters.formatCurrency(assessment.authorisedAmount, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle has-text-right money-input">{{ $filters.formatCurrency(assessment.invoicedAmount, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle has-text-centered is-content-width">
                      <div class="pretty p-icon p-curve p-smooth m-0 p-bigger">
                        <input v-model="assessment.selected"
                          @change="onAssessmentSelect($event, assessment)"
                          type="checkbox">
                        <div class="state p-info">
                          <i class="icon mdi mdi-check" />
                          <label />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="toBeInvoiceAssessments.length === 0">
                    <td :colspan="columnsToBeInvoiced.length">
                      <div class="content has-text-grey has-text-centered">
                        <span icon="icon is-large">
                          <i class="mdi mdi-48px mdi-emoticon-sad" />
                        </span>
                        <p>Nothing</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <p class="title is-size-5 mb-2">Raised Invoices
            </p>
            <div>
              <p v-if="innerValue.invoices.length > 0"
                class="is-size-7">Select invoice to view details</p>
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>{{ columnsRaisedInvoices[0] }}</th>
                    <th>{{ columnsRaisedInvoices[1] }}</th>
                    <th>{{ columnsRaisedInvoices[2] }}</th>
                    <th>{{ columnsRaisedInvoices[3] }}</th>
                    <th class="has-text-right">{{ columnsRaisedInvoices[4] }}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice in raisedInvoice"
                    :key="invoice.invoiceId"
                    :class="invoice.isSelected ? 'has-text-primary' : ''">
                    <td class="has-vertical-middle">{{ invoice.invoiceNo }}</td>
                    <td class="has-vertical-middle">{{ $filters.formatDateTimezone(invoice.invoiceDate, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle">{{ invoice.assetName }}</td>
                    <td class="has-vertical-middle">{{ invoiceTypeName(invoice.invoiceType) }}</td>
                    <td class="has-vertical-middle has-text-right money-input">{{ $filters.formatCurrency(invoice.totalIncGst, $userInfo.locale) }}</td>
                    <td class="has-vertical-middle has-text-centered is-content-width">
                      <div class="icon-container">
                        <button class="button is-primary is-small is-inverted tooltip"
                          @click="onSelectInvoice(invoice)"
                          data-tooltip="View Details">
                          <span class="icon is-small">
                            <i class="mdi mdi-eye mdi-24px"
                              :class="invoice.isSelected ? '' : 'mdi-dark'" />
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="raisedInvoice.length === 0">
                    <td :colspan="columnsRaisedInvoices.length">
                      <div class="content has-text-grey has-text-centered">
                        <span icon="icon is-large">
                          <i class="mdi mdi-48px mdi-emoticon-sad" />
                        </span>
                        <p>Nothing</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <invoice-control v-if="selectedAssessments.length > 0"
          v-model="innerValue"
          :selected-invoice="selectedInvoice"
          :assessments="selectedAssessments"
          @raise-invoice="onRaiseInvoice"
          @update-deductions="updateInvAmount" />
        <invoice-control-view v-else-if="selectedInvoice"
          v-model="innerValue"
          :selected-invoice="selectedInvoice"
          @void-invoice="deleteInvoice" />
        <div v-else>
          <p class="title is-size-5">Invoice Control</p>
          <div class="content has-text-grey has-text-centered borderd-box pb-3">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Select a job to raise / view</p>
          </div>
        </div>
      </article>
    </div>

    <quote-detail-assessment-summary-modal v-if="isSummaryModalVisible"
      :show-icon="true"
      :active.sync="isSummaryModalVisible"
      :value="innerValue"
      @close="isSummaryModalVisible = false" />
  </div>
</template>

<script>
import { InvoiceControl, InvoiceControlView, QuoteDetailAssessmentSummaryModal } from './components'
import { InvoiceTypes } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteTotalsMethodMixin from './mixins/QuoteTotalsMethodMixin'
import { QuoteAssessmentMixin } from './mixins'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'

export default {
  name: 'QuoteAssessmentInvoice',
  components: {
    InvoiceControl,
    InvoiceControlView,
    QuoteDetailAssessmentSummaryModal
  },
  filters: {},
  mixins: [QuoteTotalsMethodMixin, QuoteAssessmentMixin],
  props: {
    value: null,
    selectedInvoiceId: null,
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      authorisedAssessments: [],
      selectedInvoice: null,
      columnsToBeInvoiced: ['Quote', 'Date Created', 'Quote Amount', 'Auth. Amount', 'Inv. Amount', 'Select'],
      columnsRaisedInvoices: ['Inv No.', 'Date Invoiced', 'Payer', 'Invoice Type', 'Inv. Amount'],
      isSummaryModalVisible: false
    }
  },
  computed: {
    selectedAssessments() {
      return this.authorisedAssessments.filter((a) => a.selected)
    },
    toBeInvoiceAssessments() {
      return this.authorisedAssessments.filter((a) => !a.isInvoiced)
    },
    raisedInvoice() {
      return this.innerValue.invoices.filter((i) => i.isDeleted !== true && i.deleted !== true).sort((a, b) => b.invoiceNo - a.invoiceNo)
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = _cloneDeep(newVal)
      this.setAuthorisedAssessments()
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.setAuthorisedAssessments()
    this.clearInvoiceSelection()
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    setAuthorisedAssessments() {
      this.authorisedAssessments = []
      const authorisedAssessment = this.innerValue.quoteAssessments.filter((a) => a.authorisedAssessment && !a.deleted)
      if (authorisedAssessment) {
        authorisedAssessment.forEach((assessment) => {
          assessment.authorisedAmount = this.getAssessmentItemsDeltaTotal(assessment.authorisedAssessment.assessmentQuoteItems, this.value.quotingMethod)
          assessment.quotedAmount = this.getAssessmentItemsDeltaTotal(assessment.submittedAssessment.assessmentQuoteItems, this.value.quotingMethod)
          this.$set(assessment, 'selected', false)
          this.$set(assessment, 'invoicedAmount', this.calculateInvoiceAmount(assessment))
          this.authorisedAssessments.push(assessment)
        })
      }
    },
    onAssessmentSelect(event, assessment) {
      if (event.target.checked) {
        this.toBeInvoiceAssessments.forEach((a) => {
          if (a.subQuoteNo <= assessment.subQuoteNo) {
            a.selected = true
          }
        })
      } else {
        this.toBeInvoiceAssessments.forEach((a) => {
          if (a.subQuoteNo >= assessment.subQuoteNo) {
            a.selected = false
          }
        })
      }
      this.raisedInvoice.forEach((invoice) => {
        invoice.isSelected = false
      })
      this.selectedInvoice = null
    },
    onSelectInvoice(invoice) {
      this.raisedInvoice.forEach((invoice) => {
        invoice.isSelected = false
      })
      this.toBeInvoiceAssessments.forEach((a) => {
        a.selected = false
      })
      invoice.isSelected = !invoice.isSelected
      this.selectedInvoice = invoice
    },
    defaultSelect() {
      this.authorisedAssessments.forEach((assessment) => {
        assessment.selected = false
      })
    },
    calculateInvoiceAmount(assessment) {
      let invAmount = assessment.authorisedAmount
      if (assessment.subQuoteNo === 0) {
        let ownerContributionGst = 0
        if (!this.innerValue.excessWithGst) {
          ownerContributionGst = roundAwayFromZero((this.innerValue.ownersContribution * this.innerValue.gstRate) / 100)
        }
        invAmount = roundAwayFromZero(invAmount - this.innerValue.discount - this.innerValue.excess - this.innerValue.ownersContribution - ownerContributionGst)
      }
      return invAmount
    },
    updateInvAmount(deductions) {
      let mainAssessment = this.authorisedAssessments.find((a) => a.subQuoteNo === 0)
      if (mainAssessment) {
        mainAssessment.invoicedAmount = mainAssessment.authorisedAmount - deductions
      }
    },
    onRaiseInvoice(invoices) {
      this.innerValue.invoices.push(...invoices)
      this.selectedAssessments.forEach((assessment) => {
        assessment.isInvoiced = true
      })
      this.saveQuote()
    },
    deleteInvoice(invoices) {
      invoices.forEach((invoice) => {
        invoice.deleted = true
        invoice.isDeleted = true
        invoice.isSelected = false
        this.selectedInvoice = null
        this.authorisedAssessments.forEach((a) => {
          if (invoice.includedAssessments.some((i) => i === a.assessmentID)) {
            a.isInvoiced = false
            a.selected = false
          }
        })
      })
      this.saveQuote()
    },
    saveQuote() {
      this.$emit('input', this.innerValue)
      this.$emit('save')
    },
    openSummaryModal() {
      this.isSummaryModalVisible = true
    },
    invoiceTypeName(invoiceType) {
      return InvoiceTypes.getDisplayName(invoiceType)
    },
    clearInvoiceSelection() {
      this.innerValue.invoices.forEach((invoice) => {
        invoice.isSelected = false
      })
      if (this.selectedInvoiceId) {
        this.selectedInvoice = this.innerValue.invoices.find((i) => i.invoiceId === this.selectedInvoiceId)
      }
      else if (this.innerValue.invoices.length > 0) {
        this.innerValue.invoices[this.innerValue.invoices.length - 1].isSelected = true
        this.selectedInvoice = this.innerValue.invoices[this.innerValue.invoices.length - 1]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.is-size-8 {
  font-size: 0.65rem !important;
}
.borderd-box {
  border: 1px solid gainsboro;
}
</style>
